import Header from './Header';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { admins } from '../adminData.js'
import AboutPages from './AboutPages.js';
import Home from './Home.js';

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href =
  "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

const App = () => {
  const [user, setUser] = useState();
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')));

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            res.data.isAdmin = admins.includes(res.data.email)
            setProfile(res.data);
            localStorage.setItem('profile', JSON.stringify(res.data));
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.clear();
  };

  return (
    <div>
      <Header admin={profile ? profile.isAdmin : false} />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="edit-about-pages" element={<AboutPages />} />
        </Routes>
      </BrowserRouter>
      <footer>
        {profile ? (
          <div>
            <p>{profile.email}</p>
            <p>Admin: {profile.isAdmin !== undefined ? profile.isAdmin.toString() : "false"}</p>
            <button onClick={logOut}>Log out</button>
          </div>
        ) : (
          <button onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </footer>
    </div>
  );
};

export default App;