import { menuItems } from '../menuItemData.js';
import MenuItems from './MenuItems'
const Navbar = ({ admin }) => {
    return (
        <nav>
            <ul className="menus">
                {menuItems.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel} admin={admin} />;
                })}
            </ul>
        </nav>
    );
};

export default Navbar;