export const aboutPagesData = {
    results: [
        {
            ID: 1,
            Name: 'Atlanta Track Club',
            Contents: '',
            Published: 0,
            Feature: 0
        }
    ]
};

export default aboutPagesData;