import React, { useState } from 'react';

const AboutPageButtons = ({ aboutPage }) => {
    const [featured, setFeatured] = useState(aboutPage.Feature);
    const toggleFeature = (record) => {
        const toggleFeature = async (record) => {
            const resp = await fetch(`api/featureAboutPage`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                body: JSON.stringify(record)
            });
            const toggleFeatureResp = await resp.json();
            console.log(toggleFeatureResp);
            if (toggleFeatureResp.success) {
                const newFeatured = featured === 0 ? 1 : 0;
                aboutPage.Feature = newFeatured;
                setFeatured(newFeatured);
            }
        };
        toggleFeature(record)
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div>
            <button className="ui labeled icon button">
                <i className="trash icon"></i>
                DELETE
            </button>
            <button className="ui labeled icon button">
                <i className="edit icon"></i>
                EDIT
            </button>
            <button className="ui labeled icon button">
                {aboutPage.Published === 0 ? (
                    <>
                        <i className="eye icon"></i>
                        PUBLISH
                    </>
                ) : (
                    <>
                        <i className="eye slash icon"></i>
                        UNPUBLISH
                    </>
                )}
            </button>
            <button className="ui labeled icon button" onClick={() => toggleFeature(aboutPage)}>
                {featured === 0 ? (
                    <>
                        <i className="star icon"></i>
                        FEATURE
                    </>
                ) : (
                    <>
                        <i className="star outline icon"></i>
                        UNFEATURE
                    </>
                )}
            </button>
        </div>
    );
};

export default AboutPageButtons;