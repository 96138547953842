export const menuItems = [
    {
        title: 'News',
        url: '/news',
    },
    {
        title: 'Athletes',
        url: '/athletes',
        submenu: [
            {
                title: 'Elite Team',
                url: 'elite-team'
            },
            {
                title: 'Results',
                url: 'results'
            },
            {
                title: 'Videos',
                url: 'videos'
            },
            {
                title: 'Photos',
                url: 'photos'
            },
            {
                title: 'Team Records',
                url: 'team-records'
            }
        ]
    },
    {
        title: 'About',
        url: '/about',
        submenu: [
            {
                title: 'Edit About Pages',
                url: 'edit-about-pages',
                admin: true
            }
        ]
    }
];