import React from 'react'
import { Table } from 'semantic-ui-react'
import AboutPageButtons from './AboutPageButtons';

const AboutPagesTable = ({ aboutPages }) => (
    <Table celled compact definition>
        <Table.Header fullWidth>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Modify</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {aboutPages.map((aboutPage, index) => (
                <Table.Row key={index}>
                    <Table.Cell>{aboutPage.Name}</Table.Cell>
                    <Table.Cell><AboutPageButtons aboutPage={aboutPage} /></Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    </Table>
);

export default AboutPagesTable; 