import React, { useEffect, useState } from 'react';
import aboutPagesData from '../aboutPageData'
import AboutPagesTable from './AboutPageTable';

const AboutPages = () => {
    const [aboutPages, setAboutPages] = useState([]);

    useEffect(() => {
        const getAboutPages = async () => {
            const resp = await fetch('api/aboutPages');
            const aboutPagesResp = await resp.json();
            setAboutPages(aboutPagesResp.results);
        };

        getAboutPages()
            .catch((err) => {
                setAboutPages(aboutPagesData.results);
                console.log(err);
            });
    }, []);
    return (
        <div>
            <h1>About Pages</h1>
            <AboutPagesTable aboutPages={aboutPages} />
        </div>
    );
};

export default AboutPages;